import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router'

import {UserService} from '@core/services/http/user.service'
import {RoleCode} from '@core/schemes/role'

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const log = (...args: any): void => {
      const root = route.data.root
      const flag = root ? 'Root' : 'Child'
      const url = root ? route.routeConfig.path : state.url
      console.info(flag, ...args, url)
    }

    const guardRoles = route.data['guardRoles'] as RoleCode[]

    if (!guardRoles || guardRoles.length === 0) {
      log('[RoleGuard] No roles required for route:')
      return true
    }

    const hasAccess = this.userService.hasRoles(guardRoles)

    if (hasAccess) {
      log('[RoleGuard] Has access to route:')
      return true
    }

    log('[RoleGuard] Access denied for route:')
    this.router.navigateByUrl('/')
    return false
  }
}
